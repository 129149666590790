import * as S from './styles';
import { useHistory} from 'react-router-dom';
import React, { useState } from 'react';

interface ComboBox {
  currentUser: {
    conciliador: string;
  };
}

const ComboBox: React.FC<ComboBox> = ({ currentUser }: any) => {
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState("more_accesses");
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const cliente: any = JSON.parse(localStorage.getItem('cliente') || '{}');
    const nomeDoGrupo = cliente ? cliente.nomeDoGrupo : '';

    console.log('cliente', encodeURIComponent(nomeDoGrupo))
    
    if (event.target.value === 'option1') {
      window.open(currentUser.conciliador, '_blank', 'noopener,noreferrer');
    }
    if (event.target.value === 'option2') {
      window.open(`https://login.assessoriacartoes.com.br/ListaPortalAdministradoras/${currentUser.id}`, "_blank");
      //window.open(`http://localhost:3000/ListaPortalAdministradoras/${currentUser.id}`, "_blank");
    }
  };


  return (
    <S.MenuContainer>
      <select 
      value={selectedOption}
       onChange={handleSelectChange}>
        <option value="more_accesses" disabled hidden>
          MAIS ACESSOS
        </option>
        <option value="option1">CONCILIADOR</option>
        <option value="option2">PORTAL ADMINISTRADORAS</option>
      </select>
    </S.MenuContainer>
  );
};

export default ComboBox;