import { Layout } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../service/api';
import * as S from './styles';
import ComboBox from '../../components/ComboBox/ComboBox';

const { Header, Content } = Layout;

export type User = {
  conciliador: string;
  email: string;
  extensaoLogo: string | null;
  id: number;
  img: string;
  nomeArquivoLogo: string | null;
  password: string;
  powerBi: string;
  tipoDeUsuario: number;
};

const Home: React.FC = () => {
  let { id } = useParams<{ id: string }>();
  const [currentUser, setCurrentUser] = useState<User>();
  const [selectedView, setSelectedView] = useState<string>('dashboard'); // Valor padrão ou inicialização

  useEffect(() => {
    async function GetCliente() {
      await api.get(`api/Cliente/${id}`).then(function (response) {
        console.log(response);
        setCurrentUser(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    };
    GetCliente();
  }, [id]);

  // Simular mudança de seleção para o propósito deste exemplo
  useEffect(() => {
    if (currentUser) {
      // Defina a seleção inicial se necessário
      // Exemplo: setSelectedView('dashboard');
    }
  }, [currentUser]);

  const user: any = localStorage.getItem('user');

  return (
    <Layout className="layout">
      {currentUser && (
        <>
          <Header
            style={{
              backgroundColor: "#f0f2f5",
              display: 'flex',
              height: "auto",
              maxHeight: "60px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <S.ContainerImage>
              <img src={"data:image/png;base64," + currentUser?.img} alt="logo" />
            </S.ContainerImage>
            <S.ContainerWord>
              Análise de Dados
            </S.ContainerWord>
            <S.MenuContainer>
              <ComboBox currentUser={currentUser} />
            </S.MenuContainer>

          </Header>
          <Content style={{ backgroundColor: "white" }}>
            <S.SiteLayoutContent>
              {currentUser && selectedView === 'dashboard' && ( // Renderiza o iframe do dashboard
                <S.Iframe
                  title="Dashboard_Brasbol_Recuperado 2"
                  src={currentUser.powerBi}
                  frameBorder="0"
                  allowFullScreen
                />
              )}
              {currentUser && selectedView === 'lista' && ( // Renderiza o iframe da lista
                <S.Iframe
                  title="listaPortalAdministradoras"
                  src={'/listaPortalAdministradoras'}
                  frameBorder="0"
                  allowFullScreen
                />
              )}
            </S.SiteLayoutContent>
          </Content>
        </>
      )}
    </Layout>
  );
}

export default Home;