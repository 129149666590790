import styled from 'styled-components';

export const MenuContainer = styled.div`
  margin: 10px; /* Adiciona margem ao redor do MenuContainer */
  display: flex;
  justify-content: center; /* Centraliza horizontalmente o conteúdo */
  align-items: center; /* Centraliza verticalmente o conteúdo */

  select {
    padding: 10px; /* Adiciona preenchimento interno ao select */
    border: 1px solid #ccc; /* Borda cinza clara */
    border-radius: 4px; /* Bordas arredondadas */
    font-size: 16px; /* Tamanho da fonte */
    background-color: #f9f9f9; /* Cor de fundo do select */
    cursor: pointer; /* Muda o cursor para indicar que é clicável */

    &:focus {
      outline: none; /* Remove o contorno padrão */
    }
  }

     option {
      color: #1890FF; // Cor do texto das opções
    }
`;